




































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      items: [],
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/survey/select`)
        .then(({ data: { surveys } }) => {
          state.items = surveys
            .filter((item: any) => item.isEnabled)
            .map((el: any) => ({
              id: el.id,
              name: el.name,
            }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const selectAllSurveys = () => {
      props.value.values = state.items.map((el: any) => el.id);
    };

    const deleteItem = (item: any) => {
      props.value.values = props.value.values.filter((el: any) => el !== item);
    };

    watch(
      () => props.value.operation,
      () => {
        if (props.value.operation === null) {
          props.value.operation = "in";
        }
      }
    );

    return { state, selectAllSurveys, deleteItem };
  },
});
